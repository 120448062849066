import React from 'react';
import {SkipLink, Target} from '@nib-components/skip-link';
import {Layout} from '@nib/gatsby-theme-mesh-docs';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import {Section, Stack} from '@nib/layout';
import {Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';
//
import Header from '../components/header/Header';

import {BasicTable} from '../components/table/SimpleTable';
import {BasicTable as TypedBasicTable} from '../components/table/SimpleTable-typed';

import {SortingTable} from '../components/table/SortingTable';
import {SortingTable as TypedSortingTable} from '../components/table/SortingTable-typed';

import {RowSelectionTable} from '../components/table/RowSelectionTable';
import {RowSelectionTable as TypedRowSelectionTable} from '../components/table/RowSelectionTable-typed';

import {PaginationTable} from '../components/table/PaginationTable';
import {PaginationTable as TypedPaginationTable} from '../components/table/PaginationTable-typed';

import {FilterTable} from '../components/table/FilterTable';
import {FilterTable as TypedFilterTable} from '../components/table/FilterTable-typed';

import {ExpandingRowTable} from '../components/table/ExpandingRowTable';
import {ExpandingRowTable as TypedExpandingRowTable} from '../components/table/ExpandingRowTable-typed';

const TableTestPage = () => (
  <Layout>
    <SkipLink link="#content" text="Skip to content" />
    <Header />
    <Target id="content" component="main" style={{width: '100%'}}>
      <Section>
        <Stack space={8}>
          <Heading size={1}>Test Tables</Heading>
          <Copy>This page is for us to verify that the snippets included in the docs for the table actually work.</Copy>

          <Heading size={2}>Basic (no react-table)</Heading>
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeading>Column 1</TableHeading>
                <TableHeading>Column 2</TableHeading>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableData>Sample data</TableData>
                <TableData>Sample data</TableData>
              </TableRow>
            </TableBody>
          </Table>

          <Heading size={2}>Basic (with react-table)</Heading>
          <Heading size={3}>JS</Heading>
          <BasicTable />
          <Heading size={3}>TS</Heading>
          <TypedBasicTable />

          <Heading size={2}>Sorting</Heading>
          <Heading size={3}>JS</Heading>
          <SortingTable />
          <Heading size={3}>TS</Heading>
          <TypedSortingTable />

          <Heading size={2}>Row Selection</Heading>
          <Heading size={3}>JS</Heading>
          <RowSelectionTable />
          <Heading size={3}>TS</Heading>
          <TypedRowSelectionTable />

          <Heading size={2}>Pagination</Heading>
          <Heading size={3}>JS</Heading>
          <PaginationTable />
          <Heading size={3}>TS</Heading>
          <TypedPaginationTable />

          <Heading size={2}>Filtering</Heading>
          <Heading size={3}>JS</Heading>
          <FilterTable />
          <Heading size={3}>TS</Heading>
          <TypedFilterTable />

          <Heading size={2}>Expanding Row</Heading>
          <Heading size={3}>JS</Heading>
          <ExpandingRowTable />
          <Heading size={3}>TS</Heading>
          <TypedExpandingRowTable />
        </Stack>
      </Section>
    </Target>
  </Layout>
);

export default TableTestPage;
