import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable, getExpandedRowModel, ExpandedState, Row} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableExpandRow, TableData, TableExpandHeading} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49,
    status: 'relationship'
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31,
    status: 'relationship'
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93,
    status: 'complicated'
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77,
    status: 'complicated'
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61,
    status: 'complicated'
  }
];

export const ExpandingRowTable = () => {
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: () => <>First Name</>,
        cell: ({getValue}) => getValue(),
        footer: props => props.column.id
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    debugTable: true
  });

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
                <TableExpandHeading /> {/* Empty th for expander column */}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows>
            {table.getRowModel().rows.map(row => {
              return (
                <>
                  <TableExpandRow key={row.id} isExpanded={row.getIsExpanded()} onClick={row.getToggleExpandedHandler()}>
                    {row.getVisibleCells().map(cell => {
                      return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                    })}
                  </TableExpandRow>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row (+1 to cater for empty TableExpandHeading) */}
                      <td colSpan={row.getVisibleCells().length + 1}>{renderSubComponent({row})}</td>
                    </tr>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

const renderSubComponent = ({row}: {row: Row<Person>}) => {
  return <p>Render extra details here about {row.original.firstName}</p>;
};
