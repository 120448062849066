import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, Pagination} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49,
    status: 'relationship'
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31,
    status: 'relationship'
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93,
    status: 'complicated'
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77,
    status: 'complicated'
  },
  {
    firstName: 'Nichole',
    lastName: 'Daugherty',
    age: 40,
    visits: 495,
    progress: 82,
    status: 'complicated'
  },
  {
    firstName: 'Dixie',
    lastName: 'Bayer',
    age: 14,
    visits: 478,
    progress: 34,
    status: 'complicated'
  },
  {
    firstName: 'Bruce',
    lastName: 'Macejkovic',
    age: 34,
    visits: 43,
    progress: 99,
    status: 'relationship'
  },
  {
    firstName: 'Pietro',
    lastName: 'Koss',
    age: 6,
    visits: 624,
    progress: 60
  },
  {
    firstName: 'Karen',
    lastName: 'Torphy',
    age: 18,
    visits: 21,
    progress: 51
  },
  {
    firstName: 'Marco',
    lastName: 'Abernathy',
    age: 11,
    visits: 325,
    progress: 80,
    status: 'complicated'
  },
  {
    firstName: 'Lelah',
    lastName: 'Bradtke',
    age: 16,
    visits: 589,
    progress: 38,
    status: 'complicated'
  },
  {
    firstName: 'Meda',
    lastName: 'Thiel',
    age: 22,
    visits: 767,
    progress: 58,
    status: 'relationship'
  },
  {
    firstName: 'Elda',
    lastName: 'Berge',
    age: 7,
    visits: 639,
    progress: 3,
    status: 'complicated'
  },
  {
    firstName: 'Lucy',
    lastName: 'Block',
    age: 30,
    visits: 970,
    progress: 98,
    status: 'relationship'
  },
  {
    firstName: 'Adan',
    lastName: 'Mante',
    age: 12,
    visits: 326,
    progress: 77
  },
  {
    firstName: 'Patricia',
    lastName: 'Friesen',
    age: 11,
    visits: 954,
    progress: 75,
    status: 'relationship'
  },
  {
    firstName: 'Katheryn',
    lastName: 'Walter',
    age: 11,
    visits: 558,
    progress: 33,
    status: 'relationship'
  },
  {
    firstName: 'Adelle',
    lastName: 'Armstrong',
    age: 13,
    visits: 560,
    progress: 47,
    status: 'relationship'
  },
  {
    firstName: 'Mose',
    lastName: 'Rowe',
    age: 18,
    visits: 198,
    progress: 42,
    status: 'relationship'
  },
  {
    firstName: 'Itzel',
    lastName: 'Fritsch',
    age: 17,
    visits: 193,
    progress: 28,
    status: 'complicated'
  },
  {
    firstName: 'Reinhold',
    lastName: 'Wiza',
    age: 17,
    visits: 390,
    progress: 67,
    status: 'relationship'
  },
  {
    firstName: 'Giuseppe',
    lastName: 'Turner',
    age: 14,
    visits: 111,
    progress: 59
  },
  {
    firstName: 'Annalise',
    lastName: 'Barrows',
    age: 3,
    visits: 837,
    progress: 89,
    status: 'relationship'
  },
  {
    firstName: 'Lilliana',
    lastName: 'Donnelly',
    age: 12,
    visits: 755,
    progress: 83,
    status: 'complicated'
  },
  {
    firstName: 'Monique',
    lastName: 'Klein',
    age: 8,
    visits: 70,
    progress: 72
  },
  {
    firstName: 'Leland',
    lastName: 'Halvorson',
    age: 28,
    visits: 388,
    progress: 4
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61,
    status: 'complicated'
  }
];

export const PaginationTable = () => {
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });
  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          pageOptions={table.getPageOptions()}
          pageCount={table.getPageCount()}
          gotoPage={table.setPageIndex}
          nextPage={table.nextPage}
          previousPage={table.previousPage}
          setPageSize={table.setPageSize}
          pageIndex={table.getState().pagination.pageIndex}
          pageSize={table.getState().pagination.pageSize}
        />
      </TableWrapper>
    </>
  );
};
