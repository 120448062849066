import React, {useId} from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, IndeterminateCheckbox} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14
  }
];

export const RowSelectionTable = () => {
  const [rowSelection, setRowSelection] = React.useState({});
  const id = useId();
  const id2 = useId();
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        id: 'select',
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              id,
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({row}) => (
          <div>
            <IndeterminateCheckbox
              {...{
                id: `row-${row.id}-${id2}`,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        )
      },
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>,
        enableColumnFilter: false
      },
      {
        accessorFn: row => row.lastName,
        enableColumnFilter: false,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        enableColumnFilter: false,
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        enableColumnFilter: false,
        header: 'Status',
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        enableColumnFilter: false,
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true
  });

  return (
    <div>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
      <div>
        {Object.keys(rowSelection).length} of {table.getPreFilteredRowModel().rows.length} Total Rows Selected
      </div>
    </div>
  );
};
