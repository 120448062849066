import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, FilterFn} from '@tanstack/react-table';
import {rankItem} from '@tanstack/match-sorter-utils';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, SearchBox} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61
  }
];

export const FilterTable = () => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const [globalFilter, setGlobalFilter] = React.useState('');

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const columns = React.useMemo<ColumnDef<Person, any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorFn: row => `${row.firstName} ${row.lastName}`,
        id: 'fullName',
        header: 'Full Name',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        enableColumnFilter: false,
        footer: props => props.column.id
      },

      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: false,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        enableColumnFilter: false,
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <div>
      <div>
        <DebouncedInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} placeholder="Search all columns..." />
      </div>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
};

// Anm example debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, onChange, debounce]);

  return <SearchBox value={value || ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} placeholder="Filter table" {...props} />;
}
